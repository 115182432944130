import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: "PearZap",
    url: "https://app.pearzap.com/",
    chef: {
        address: "0xb12FeFC21b12dF492609942172412d4b75CbC709",
        rewardTokenTicker: "PEAR",
        abi: Chef,
        rewardTokenFunction: "pear",
        pendingRewardsFunction: "pendingPear"
    },
    swap: SwapPlatforms.OneInch
}

export default config;
